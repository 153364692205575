<template>
  <q-form ref="editForm">
    <c-card title="체크박스 컴포넌트" height="700px" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :editable="editable"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            label="기본"
            name="col1"
            v-model="data.col1">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :editable="editable"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            label=""
            name="col1"
            v-model="data.col1">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :required="true"
            :editable="editable"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            label="필수값"
            name="col1"
            v-model="data.col1">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :disabled="true"
            :editable="editable"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            label="disable"
            name="col1"
            v-model="data.col1">
          </c-checkbox>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'checkbox',
  data() {
    return {
      editable: true,
      data: {
        col1: [],
      },
      comboItems: [
        { code: '1', codeName: '1' },
        { code: '2', codeName: '2' },
        { code: '3', codeName: '3' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
